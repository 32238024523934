<template>
  <div>
    <div v-if="skeleton || skeleton2"
         class="skeleton">
      <van-skeleton title
                    :row="20" />
    </div>
    <div class="home"
         v-else>
      <div class="head">
        <div class="head_bg">
          <img :src="form.businessCard.headPic"
               alt
               class="head_img" />
        </div>
        <p class="name">{{ form.businessCard.name }}
          <img v-if="form2.openId"
               class="cert_icon"
               src="../assets/images/v.png"
               alt="">
        </p>
        <p class="remark">{{ form.businessCard.shareWork }}</p>
        <img src="../assets/images/图层 55.png"
             alt
             class="head_tel"
             @click="tel(form.businessCard.mobile)" />
      </div>
      <div class="desc">
        <p @click="rotate = !rotate">
          {{ !rotate ? "展开" : "收起" }}全部信息
          <img src="../assets/images/标题栏左箭头.png"
               alt
               :class="{ rotate: rotate }" />
        </p>
        <ul v-show="rotate">
          <li>
            <span class="span1">电话：</span>
            <span class="span2">{{ $getPhone(form.businessCard.mobile) }}</span>
            <span class="span3"
                  @click="tel(form.businessCard.mobile)">拨打</span>
          </li>
          <!-- <li>
            <span class="span1">微信：</span>
            <span class="span2">{{ form.businessCard.wechatNo }}</span>
            <span
              class="span3"
              v-clipboard:copy="form.businessCard.wechatNo"
              v-clipboard:success="onCopy"
            >复制</span>
          </li>-->
          <li>
            <span class="span1">邮箱：</span>
            <span class="span2">{{ form.businessCard.email }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.email"
                  v-clipboard:success="onCopy">复制</span>
          </li>
          <li>
            <span class="span1">公司：</span>
            <span class="span2">{{ form.businessCard.company }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.company"
                  v-clipboard:success="onCopy">复制</span>
          </li>
          <li>
            <span class="span1">地址：</span>
            <span class="span2">{{ form.businessCard.address }}</span>
            <span class="span3"
                  v-clipboard:copy="form.businessCard.address"
                  v-clipboard:success="onCopy">复制</span>
          </li>
        </ul>
      </div>
      <div class="icon">
        <!-- <div class="icon_item"
             @click="authentication">
          <img src="../assets/images/待认证.png"
               alt
               v-if="!mobile" />
          <img src="../assets/images/已认证.png"
               alt
               v-else />
          <p>{{ mobile ? "已认证" : "待认证" }}</p>
        </div> -->
        <div class="icon_item"
             @click="go('/card?id=' + form.businessCard.id)">
          <img src="../assets/images/名片.png"
               alt />
          <p>智能名片</p>
        </div>
        <div class="icon_item"
             @click="qr_show(form.businessCard.wechatQrCode, 6)">
          <img src="../assets/images/二维码.png"
               alt />
          <p>个人微信</p>
        </div>
        <div class="icon_item"
             @click="qr_show(form.businessCard.wechatWorkQrCode, 7)">
          <img src="../assets/images/二维码.png"
               alt />
          <p>企业微信</p>
        </div>
        <div class="icon_item"
             @click="trailSys()">
          <img src="../assets/images/trail.png"
               alt />
          <p>试用系统</p>
        </div>
      </div>
      <!-- <div class="loop">
      <Swipe class="my-swipe" :autoplay="3000" indicator-color="#FF6E03">
        <SwipeItem>1</SwipeItem>
        <SwipeItem>2</SwipeItem>
        <SwipeItem>3</SwipeItem>
        <SwipeItem>4</SwipeItem>
      </Swipe>
      </div>-->
      <div class="list">
        <div class="tt">头条</div>
        <ul>
          <li v-for="item in list"
              :key="item.id"
              @click="goD(item)">
            <h3>
              <img src="../assets/images/1 (3).png"
                   alt />
              {{ item.name }}
            </h3>
            <p>{{ item.summary }}</p>
            <div class="other">
              <span>
                <img src="./../assets/images/1 (2).png"
                     alt />
                {{ item.readQty }}阅读
              </span>
              <span>{{ item.createTime }}</span>
            </div>
          </li>
        </ul>
      </div>
      <qrcode v-model="show"
              :src="qrcode"></qrcode>
    </div>

    <van-dialog v-model="dialogshow"
                title="绑定手机号"
                show-cancel-button
                confirmButtonColor="#1989fa"
                @confirm="$refs.bindTel.onSubmit()">
      <bindTel @success="
          dialogshow = false;
          mobile = true;
        "
               ref="bindTel"></bindTel>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import qrcode from "@/components/qrcode";
import { Swipe, SwipeItem } from "vant";
import mixin from "../utils/share";
import ladingPage from "../utils/ladingPage";
import bindTel from "./bind.vue";
export default {
  name: "Home",
  mixins: [mixin, ladingPage],

  data () {
    return {
      rotate: false,
      pageNum: 1,
      qrcode: "",
      skeleton2: true,
      form2: {},
      ladingPageId: localStorage.getItem("ladingPageId"),
      dialogshow: false,
      show: false,
      list: [],
    };
  },
  components: {
    Swipe,
    qrcode,
    bindTel,
    SwipeItem,
  },
  created () {
    this.getList();
    this.$api
      .get(`/api/crm/businessCard/get?id=${this.$route.query.id}`)
      .then((res) => {
        this.form2 = res.result;
        this.skeleton = false;
        this.$nextTick(() => {
          this.form.businessCard = res.result || {}
          localStorage.setItem("tel", this.form.businessCard.mobile);
          localStorage.setItem("www", this.form.businessCard.www);
          localStorage.setItem("id", this.form.id);
          localStorage.setItem("businessCardId", this.form.businessCard.id);
          localStorage.setItem(
            "address",
            `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.form.businessCard.lat},${this.form.businessCard.lng};title:${this.form.businessCard.company};addr:${this.form.businessCard.address}`
          );
          localStorage.setItem("productUrl", this.form.businessCard.productUrl || '');
          localStorage.setItem("openProduct", this.form.businessCard.openProduct || false);
          this.share();
          this.geet();
        });
      });
    // this.time = setInterval(() => {
    //   // this.Action(2);
    // }, 15000);
  },
  mounted () {
    let _this = this;
    window.onscroll = function () {
      if (_this.$route.name === 'flow') {
        return
      }
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight) {
        _this.pageNum += 1;
        _this.getList();
      }
    };
  },
  methods: {
    trailSys () {
      // this.$api
      //   .post("/api/crm/article/list", {
      //     pageNum: 1,
      //     pageSize: 1,
      //     orderByDesc: "createTime",
      //     formId: 1301960739979264, // 【我公司要上供应链公司软件系统】表单
      //   })
      //   .then((res) => {
      //     let array = res.result.list || []
      //     if (array[0]) {
            
      //     }
      //   });
      this.$router.push(
        `/trail?id=${localStorage.getItem("ladingPageId") || 0
        }&businessCardId=${this.form.businessCard.id}&channelId=${localStorage.getItem("channelId") || 0
        }&formId=1301960739979264`
      );
    },
    getList () {
      this.$api
        .post("/api/crm/article/list", {
          pageNum: this.pageNum,
          pageSize: 10,
          orderByDesc: "createTime",
          // businessCardId: this.form.businessCardId,
        })
        .then((res) => {
          let defaultIds = [9658002480824320, 9658001230921728, 9657999577907200, 867024656400384, 1036973216169984]
          let array = (res.result.list || []).filter(item => !defaultIds.includes(item.id))
          this.list = [...this.list, ...array];
          this.skeleton2 = false;
          this.form.article = {
            name: this.list[0].name,
            summary: this.list[0].summary,
            $id: this.list[0].id,
          };
          this.geet();
        });
    },
    goD (item) {
      this.$router.push(
        `/details?id=${localStorage.getItem("ladingPageId") || 0
        }&businessCardId=${this.form.businessCard.id}&channelId=${localStorage.getItem("channelId") || 0
        }&articleId=${item.id}`
      );
    },
    authentication () {
      if (!this.mobile) {
        this.dialogshow = true;
      } else {
        this.$toast("已成功绑定");
      }
    },
    goCard () {
      //
    },
    qr_show (src, num) {
      this.qrcode = src;
      this.show = true;
      this.Action(num);
    },
    tel (value) {
      window.location.href = `tel:${value}`;
      this.Action(8);
    },
    go (url) {
      this.$router.push(url);
    },
    onCopy (e) {
      Toast.success("复制成功");
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.home {
  background: #f9f9f9;
}
.head_bg {
  background: url("../assets/images/fff.png");
  background-size: 100% 100%;
  width: 217px;
  height: 217px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.my-swipe {
  height: 100%;
}
.rotate {
  transform: rotate(180deg);
}
.head {
  background: url("../assets/images/矩形 5.png");
  overflow: hidden;
  background-size: 100% 100%;
  height: 400px;
  position: relative;
  .head_img {
    margin: 0 auto;
    display: block;
    width: 188px;
    height: 188px;
    border-radius: 50%;
  }
  .cert_icon {
    height: 40px;
    vertical-align: -0.2em;
  }
  .head_tel {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    bottom: 22px;
    right: 20px;
  }
  .name {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    padding: 18px;
  }
  .remark {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    opacity: 0.6;
    text-align: center;
  }
}
.loop {
  height: 250px;
  background: #ffffff;
}
.list {
  margin-top: 35px;
  position: relative;
  .tt {
    position: absolute;
    background: url("../assets/images/1 (1).png");
    background-size: 100% 100%;
    width: 110px;
    height: 50px;
    font-size: 33px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    padding-right: 20px;
    top: -20px;
    left: 20px;
  }
  li {
    padding: 40px 20px;
    margin-bottom: 16px;
    overflow: hidden;
    background: #ffffff;
    h3 {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
      img {
        width: 15px;
        height: 15px;
        position: relative;
        top: -3px;
        margin-right: 5px;
      }
    }
    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3c3c3;
      padding-top: 23px;
    }
  }
  .other {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
    img {
      width: 28px;
      height: 18px;
      margin-right: 5px;
    }
    span {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3c3c3;
    }
  }
}
.icon {
  height: 202px;
  margin: 18px 0;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  .icon_item {
    width: 116px;
    margin-top: 33px;
    img {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      display: block;
    }
    p {
      font-size: 28px;
      padding-top: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
      text-align: center;
    }
  }
}
.desc {
  background: #ffffff;
  padding-top: 35px;
  p {
    text-align: center;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-bottom: 20px;
    img {
      width: 20px;
      height: 10px;
      margin: 5px;
      margin-left: 13px;
      position: relative;
      top: 1px;
    }
  }
  ul {
    padding: 0 22px;
    padding-top: 28px;
    padding-bottom: 20px;
    li {
      margin-bottom: 20px;
      display: flex;
      .span1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b8b8b8;
      }
      .span2 {
        font-size: 0.32rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363636;
        padding-left: 0.26667rem;
        /* max-width: 7.06667rem; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        flex: 1;
        padding-right: 0.4rem;
      }
      .span3 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6e03;
        text-align: right;
      }
    }
  }
}
</style>
